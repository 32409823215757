/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Avatar, Button, IconButton, Modal } from 'rsuite';
import EditIcon from '@rsuite/icons/Edit';
import PropTypes from 'prop-types';
import { Plus } from '@rsuite/icons';
import { toast } from 'react-toastify';
import ModalAdicionarCandidato from './ModalAdicionarCandidato';
import api from '../../../../services/api';
import CelulaAcao from '../../../Pedidos/RegistrosDoDia/components/CelulaAcao';
import { DarkModeContext } from '../../../../common/contexts/darkMode';
import theme from '../../../../styles/theme';


export const ModalListaRecrutamentoProf = ({
  exibirModal,
  setExibirModal,
  idRecrutamento,
  carregando,
  funcExec,
}) => {
  const [novoCandidato, setNovoCandidato] = useState(false)
  const [editarCandidato, setEditarCandidato] = useState(false)
  const [candidatosEntrevista, setCandidatosEntrevista] = useState([])
  const [candidatoSelecionado, setCandidatoSelecionado] = useState();

  const { toggleMode } = useContext(DarkModeContext)

  const buscarCandidtosEntrevista = useCallback(async () => {
    try {
      if (idRecrutamento !== undefined) {
        const resposta = await api.get(`/candidato-entrevista/index/${idRecrutamento}`);
        const data = resposta.data.map((item) => ({
          ...item,
          imagem: item.arquivo ? item.arquivo.caminho : '',
        }));

        setCandidatosEntrevista(data);
      }


    } catch (e) {
      toast.error(e.message);
    }
  }, [idRecrutamento]);

  useEffect(() => {
    buscarCandidtosEntrevista();
  }, [buscarCandidtosEntrevista]);

  function abrirEditarCandidato(newData) {
    setCandidatoSelecionado({ ...newData });
    setEditarCandidato(true);
  }

  useEffect(() => {
    if (editarCandidato === false)
      setCandidatoSelecionado({})
  }, [editarCandidato]);

  const FecharModal = () => {
    setExibirModal(false);
    setCandidatoSelecionado({});
    idRecrutamento = '';
  }
  const corBackGround = (favorito) => {
    if (favorito) {
      return theme.lightGreen
    } if (!favorito && toggleMode) {
      return theme.darkGray
    }
    return 'd3d3d3'

  }

  return (

    <Modal open={exibirModal} onClose={() => FecharModal()} size="md">
      <Modal.Header>
        <div style={{ display: 'flex', alignItems: 'center', }}>
          <Modal.Title><h3>Lista de candidatos</h3></Modal.Title>
          <div
            style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', width: '300px' }}
          >
            <h6 style={{ marginRight: 2 }}> Adicionar Candidato </h6>
            <IconButton
              style={{ marginTop: '0', marginLeft: 4 }}
              icon={<Plus />}
              onClick={() => {
                setNovoCandidato(true);
              }}
            />
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div style={{ gap: '10px' }}>
          {candidatosEntrevista && candidatosEntrevista.map((item) => {
            return (
              <div style={{
                margin: 'auto',
                width: '500px',
                height: '100px',
                display: 'flex',
                marginBottom: '10px',
                backgroundColor: corBackGround(item.favorito),

                justifyContent: 'space-between',
                alignItems: 'center',
                borderRadius: "10px",
                boxShadow: toggleMode ? 'none' : "1px 1px 4px 1px #989898",
              }}>
                <div style={{
                  width: 'inherit',
                  height: 'inherit',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  margin: '0px 20px',
                  marginBottom: 'inherit'
                }}>

                  <Avatar size="lg" circle src={item.imagem ? item.imagem : ""} />
                  <p style={{ color: toggleMode ? 'white' : 'black' }}>{item.nome}</p>
                  <div>
                    <IconButton
                      style={{ color: toggleMode ? 'white' : 'black' }}
                      appearance="subtle"
                      onClick={() => abrirEditarCandidato(item)}
                      icon={<EditIcon />}
                    />
                  </div>
                </div>
              </div>
            )
          })}
        </div>

      </Modal.Body>
      <ModalAdicionarCandidato
        exibir={novoCandidato}
        fechar={() => setNovoCandidato(false)}
        idRecrutamento={idRecrutamento}
        buscarCandidtosEntrevista={buscarCandidtosEntrevista}
      />
      <ModalAdicionarCandidato
        exibir={editarCandidato}
        fechar={() => setEditarCandidato(false)}
        candidatoEdit={candidatoSelecionado}
        idRecrutamento={idRecrutamento}
        buscarCandidtosEntrevista={buscarCandidtosEntrevista}
      />
    </Modal >
  );
}

ModalListaRecrutamentoProf.propTypes = {
  exibirModal: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  // eslint-disable-next-line react/require-default-props
  carregando: PropTypes.bool,
  setExibirModal: PropTypes.func.isRequired,
  funcExec: PropTypes.func.isRequired,
  idRecrutamento: PropTypes.string.isRequired,
};
