/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-return-assign */
import React, { useRef, useState, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Form, Button, Modal, Input } from 'rsuite';
import PropTypes from 'prop-types';
import InputMascara from '~/components/InputMascara';

import api from '~/services/api';
import { ButtonFullBlue } from '~/components/Buttons/ButtonFullBlue/styles';
import { toast } from 'react-toastify';


function ModalAdicionarCandidato({ exibir, fechar, salvar, candidatoEdit, idRecrutamento, buscarCandidtosEntrevista }) {
  const [nome, setNome] = useState();
  const [descricao, setDescricao] = useState();
  const [imagem, setImagem] = useState();
  const [carregando, setCarregando] = useState(false);

  const editorRef = useRef(null);

  const formData = new FormData();
  async function salvarCandidato() {
    try {
      formData.append('candidatos_entrevista', imagem);
      formData.append('nome', nome);
      formData.append('descricao', descricao);
      formData.append('id_recrutamento', idRecrutamento);
      setCarregando(true);
      await api.post(`/candidato-entrevista/create`, formData);
      toast.success('Novo candidato adicionado com sucesso.');
    } catch (e) {
      toast.error(e.message);
    } finally {
      setDescricao()
      setNome()
      setCarregando(false);
      fechar()
      buscarCandidtosEntrevista()
    }
  }


  const editandoCandidato = !!(candidatoEdit && candidatoEdit.id > 0)
  useEffect(() => {
    if (editandoCandidato) {
      setNome(candidatoEdit.nome)
      setDescricao(candidatoEdit.descricao)
      setImagem(candidatoEdit.arquivo)
    }
  }, [editandoCandidato]);


  async function editarCandidato() {
    formData.append('candidatos_entrevista', imagem);
    formData.append('nome', nome);
    formData.append('descricao', descricao);
    formData.append('id_recrutamento', idRecrutamento);
    try {
      await api.put(`/candidato-entrevista/update/${candidatoEdit.id}`, formData);
      toast.success('Candidato atualizado com sucesso.');
    } catch (e) {
      toast.error(e.message);
    } finally {
      setDescricao()
      setNome()
      setCarregando(false);
      fechar()
      buscarCandidtosEntrevista()
    }
  }

  return (
    <Modal open={exibir} onClose={() => fechar()} size="xs">
      <Form
        fluid
        // model={modelo}
        // onChange={setCamposInput}
        // formDefaultValue={camposInput}
        onSubmit={() => editandoCandidato ? editarCandidato() : salvarCandidato()}
      >
        <Modal.Header>
          <Modal.Title>{editandoCandidato ? 'Editar Candidato' : 'Adicionar Candidato'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.ControlLabel>Nome:</Form.ControlLabel>
            <Input
              placeholder="Preencha o nome"
              value={nome || ''}
              onChange={(value) =>
                setNome(value)
              }
            />
          </Form.Group>
          <Form.Group controlId="imageProf">
            <Form.ControlLabel>Imagem</Form.ControlLabel>
            <Form.Control
              name="imagem"
              type="file"
              style={{ width: '100%' }}
              accept="image/*"
              onChange={(_, evento) => {
                setImagem(evento.target.files[0]);
              }}
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>Experiência:</Form.ControlLabel>
            <Editor
              onInit={(evt, editor) => (editorRef.current = editor)}
              apiKey="pv8hnike17xcj19zp9782tyg170swsns0wxumltslkzn2j8d"
              init={{
                menubar: 'custom',
                plugins: ['emoticons', 'link'],
                language: 'pt_BR',
                language_url: '/tiny_lang/pt_BR.js',
                toolbar:
                  'emoticons | bold italic underline strikethrough | fontsizeselect | link',
                fontsize_formats: '16px 22px 26px',
                content_style: `
              body {text-align: start;}
             `,
                preview_styles: 'background-color',
                required: true,
              }}
              value={descricao || ''}
              onEditorChange={(value) =>
                setDescricao(value)
              }
            />
          </Form.Group>

        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => fechar()} appearance="subtle">
            Cancelar
          </Button>
          <ButtonFullBlue
            type="submit"
            // loading={carregando}
            appearance="primary"
          >
            Confirmar
          </ButtonFullBlue>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

ModalAdicionarCandidato.propTypes = {
  exibir: PropTypes.bool.isRequired,
  fechar: PropTypes.func.isRequired,
  salvar: PropTypes.func.isRequired,
  buscarCandidtosEntrevista: PropTypes.func.isRequired,
  idRecrutamento: PropTypes.string.isRequired,
  candidatoEdit: PropTypes.func.isRequired,
};

export default ModalAdicionarCandidato;
