import React, { useCallback, useEffect, useState, useContext } from 'react';
import { FlexboxGrid, Button } from 'rsuite';
import { toast } from 'react-toastify';
import { startOfDay, endOfDay } from 'date-fns';
import PropTypes from 'prop-types';
import api from '~/services/api';
import { DarkModeContext } from '~/common/contexts/darkMode';
import theme from '../../styles/theme';
import { Grid, StyledPanel } from './styles';




function BotaoTodos(conteudo, status, setData, setStatusSelecionado) {
  function setFiltrosTodos() {
    setData([]);
    setStatusSelecionado([status]);
  }
  const { toggleMode, setToggleMode } = useContext(DarkModeContext);



  return (
    <FlexboxGrid.Item colspan={12} style={{ paddingLeft: '10px' }}>
      <span style={{ color: toggleMode ? 'gray' : 'black' }}>Todos </span>
      <br />
      <Button onClick={() => setFiltrosTodos(status)} appearance="subtle">
        <h4 style={{ color: toggleMode ? 'gray' : theme.darkGray }}>{conteudo}</h4>
      </Button>
    </FlexboxGrid.Item>
  );
}

function CardsPedidos({
  data,
  setData,
  setStatusSelecionado,
  fecharEditarPedido,
}) {
  const [painel, setPainel] = useState({});
  const [painelTodos, setPainelTodos] = useState({});
  function setFiltrosHoje(status) {
    setData([startOfDay(new Date(data)), endOfDay(new Date(data))]);
    setStatusSelecionado([status]);
  }
  const { toggleMode, setToggleMode } = useContext(DarkModeContext);
  const Card = ({ titulo, conteudo, status }) => (
    <StyledPanel shaded darkMode={toggleMode}>
      <h4>{titulo}</h4>
      <FlexboxGrid >
        <FlexboxGrid.Item colspan={12} style={{ paddingRight: '10px' }}>
          <span style={{ color: toggleMode ? 'gray' : 'black' }} >Hoje </span>
          <br />
          <Button onClick={() => setFiltrosHoje(status)} appearance="subtle">
            <h4 style={{ color: toggleMode ? '#1675e0' : theme.primaryColor }}>{conteudo}</h4>
          </Button>
        </FlexboxGrid.Item>

        <FlexboxGrid.Item colspan={12}>
          {status === 'Aberto' &&
            BotaoTodos(
              painelTodos.totalAbertos,
              status,
              setData,
              setStatusSelecionado
            )}
          {status === 'Autorizado' &&
            BotaoTodos(
              painelTodos.totalAutorizados,
              status,
              setData,
              setStatusSelecionado
            )}
          {status === 'Confirmado' &&
            BotaoTodos(
              painelTodos.totalConfirmados,
              status,
              setData,
              setStatusSelecionado
            )}
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </StyledPanel>
  );
  Card.propTypes = {
    titulo: PropTypes.string.isRequired,
    conteudo: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  };

  const getDadosPainel = useCallback(async () => {
    try {
      const resposta = await api.get('pedidos/detalhes', {
        params: {
          data: new Date(data),
        },
      });
      const status = {
        autorizados: 0,
        abertos: 0,
        confirmados: 0,
        finalizados: 0,
        cancelados: 0,
      };
      const { totalAbertos, totalAutorizados, totalConfirmados } =
        resposta.data;
      setPainelTodos({ totalAbertos, totalAutorizados, totalConfirmados });
      resposta.data.pedidos.map((pedido) => {
        if (pedido.status === 'Autorizado') {
          status.autorizados = pedido.count;
          return status.autorizados;
        }
        if (pedido.status === 'Confirmado') {
          status.confirmados = pedido.count;
          return status.confirmados;
        }
        if (pedido.status === 'Cancelado') {
          status.cancelados = pedido.count;
          return status.cancelados;
        }
        if (pedido.status === 'Finalizado') {
          status.finalizados = pedido.count;
          return status.finalizados;
        }
        if (pedido.status === 'Aberto') {
          status.abertos = pedido.count;
          return status.abertos;
        }
        return status;
      });
      setPainel(status);
    } catch (e) {
      toast.error(e.message);
    }
  }, [data]);

  useEffect(() => {
    getDadosPainel();
  }, [getDadosPainel, fecharEditarPedido]);

  return (
    <FlexboxGrid justify="start" style={{ paddingTop: '30px', gap: '15px' }}>
      <Grid>
        <Card conteudo={painel.abertos} titulo="Abertos" status="Aberto" />
      </Grid>
      <Grid>
        <Card
          conteudo={painel.autorizados}
          titulo="Autorizados"
          status="Autorizado"

        />
      </Grid>
      <Grid>
        <Card
          conteudo={painel.confirmados}
          titulo="Confirmados"
          status="Confirmado"
        />
      </Grid>
      <Grid>
        <Card
          conteudo={painel.finalizados}
          titulo="Finalizados"
          status="Finalizado"
        />
      </Grid>
      <Grid>
        <Card
          conteudo={painel.cancelados}
          titulo="Cancelados"
          status="Cancelado"
        />
      </Grid>
    </FlexboxGrid>
  );
}
export default CardsPedidos;

CardsPedidos.propTypes = {
  data: PropTypes.arrayOf.isRequired,
  setData: PropTypes.arrayOf.isRequired,
  setStatusSelecionado: PropTypes.func.isRequired,
  status: PropTypes.shape({
    autorizados: PropTypes.number.isRequired,
    abertos: PropTypes.number.isRequired,
    confirmados: PropTypes.number.isRequired,
    finalizados: PropTypes.number.isRequired,
    cancelados: PropTypes.number.isRequired,
  }).isRequired,
  fecharEditarPedido: PropTypes.func.isRequired,
};
