import formatCurrency from '~/utils/formatCurrency'
import JSPDF from 'jspdf';
import JsBarcode from 'jsbarcode';
import QRCode from 'qrcode'
import api from '~/services/api'
import { switchLogo } from '~/assets/SwitchLogo';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns'

export async function exportPDFStark(fatura, zip) {

    console.log(fatura)
    try {

        const { valor, vencimento, linha_digitavel, brcode, codigo_barras, caminho } = fatura;
  
        const getPedidos = await api.get(`/pagamentos/getFaturas/pedidos/${fatura.id}`)
  
        const pedidosFatura = getPedidos.data.map((map) => ({
          ...map,
          valor: formatCurrency(map.valor_bruto),
          data: format(parseISO(map.data), 'dd/MM/yyyy')
        }))

        const unit = 'pt';
        const size = 'A3'; 
        const orientation = 'landscape'; 
  
        const marginLeft = 40;
        const doc = new JSPDF(orientation, unit, size);
  
        const title = `- Pagamento`;

        doc.addImage(switchLogo, 'JPEG', 40, 40, 120, 20);
        doc.text(title, 167, 56);
  
        doc.setFontSize(18);
        doc.setFont("Helvetica");

  
        // Cabeçalho do boleto
        doc.text("Banco: STARK BANK S.A.", 40, 80);
        doc.text("QR Code", 905, 168)
        if(typeof fatura.imagem_base64 === 'string'){
          doc.addImage(fatura.imagem_base64, 'PNG', 800, 170, 300, 300)
        }
        
  
        // Dados do boleto
        doc.text(`Data de Vencimento: ${format(new Date(vencimento), 'dd/LL/yyyy')}`, 40, 99);
        doc.text(`Valor do Documento: ${valor}`, 40, 118);
        doc.text(`Pagador: ${fatura.nome_fantasia}`, 40 , 137)
  
        doc.line(35, 150, 500, 150);
  
        doc.rect(32, 200, 520, 30);
        doc.text("Número do Boleto:", 40, 190);
        doc.text(linha_digitavel, 40, 220);
  
        doc.setFontSize(18);
        doc.text("Pix Copia e Cola:", 40, 560)
        doc.setFontSize(12); 
        doc.text(brcode, 40, 580);
        doc.setFontSize(18); 
  
        // const canvas = document.createElement("canvas");
        // JsBarcode(canvas, codigo_barras, {
        //   format: "CODE128",
        //   displayValue: false,
        //   height: 40
        // });
  
        
        doc.setFontSize(12)
        doc.setFontSize(14); 
        
        // Informações comentadas para uso futuro
        // doc.addPage()
        // doc.setFontSize(18);
        // doc.text("Resumo da Fatura:", 24, 24);
        // doc.text(`Total: ${valor}`, 1000, 24);
        // doc.rect(10, 30, 1170, 800);
  
        // if(pedidosFatura.length < 15) {
        //   doc.setFontSize(14)
        // }else {
        //   doc.setFontSize(12)
        // }
        // let startY = 50;
        // pedidosFatura.forEach((registro, index) => {
        //     doc.text(`Pedido: ${registro.numero_pedido} | Valor: ${registro.valor} | Data: ${registro.data} | Profissão: ${registro.profissao}`, 20, startY);
        //     startY += 20;
        // });
        

        if (zip) {
          zip.file(
            `${fatura.nome_fantasia} - ${fatura.id} - Pagamento.pdf`,
            doc.output('blob')
          );
        } else {
          doc.save(
            `${fatura.nome_fantasia} - ${fatura.id} - Pagamento.pdf`
          );
        }
      } catch (error) {
        console.log(error)
        toast.error(error.message);
      } 
}